<template>
  <ContentSection id="success-text" color-mode="transparent">
    <LibRichTextRenderer
      :rich-text="$dictionary['downloadCenter.modal.success']"
    />
    <div class="align-self-center d-flex flex-column gap-4">
      <LinkElement
        v-if="downloadLink"
        style-type="attraction"
        :link="downloadLink"
      >
        {{
          $textDictionary[
            'downloadCenter.modal.success.download_again_button.label'
          ] ?? 'Try again'
        }}
      </LinkElement>
      <Button
        style-type="secondary"
        class="mb-4 align-self-center"
        @click="handeBackToOverview"
      >
        {{
          $textDictionary['downloadCenter.modal.success.back_button.label'] ??
          'Back to overview'
        }}
      </Button>
    </div>
  </ContentSection>
</template>

<script setup lang="ts">
import {
  Button,
  ContentSection,
  LinkElement
} from '@hypercodestudio/basler-components';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';

interface Props {
  downloadLink?: HyperlinkInterface;
  handeBackToOverview: () => void;
}

defineProps<Props>();

const { $dictionary, $textDictionary } = useNuxtApp();
</script>
